@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/derived-variables.sass';

/***************************
********* Colors 🎨 ********
***************************/
$primary: #00375d;
$dark-primary: #003152;
$darker-primary: #002c49;

$yellow: #f2b600;

$red: #e90000;

$white: #fff;

$grey: #f3f2f1; //Example: left menu
$light-grey: #fbfaf9; //Example: base background
$dark-grey: #605e5c;

$black: #323130; //Default: Font color

$green: #3cb078;

//Used to add colors as bulma helpers
/* stylelint-disable-next-line function-no-unknown */
$colors: map-merge($colors, ( 'dark-primary': ( $primary, $dark-primary, ),));

/***************************
******** Settings ⚙ *******
***************************/
$menu-width: 275px;
$menu-close-width: 4em;
$nav-bar-height: 52px;
$top-bar-height: 44px;
