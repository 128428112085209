@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.documents-data {
  ul.documents-data-list {
    li {
      display: flex;
      justify-content: space-between;

      > span {
        &:first-child {
          color: variables.$primary;
          cursor: pointer;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          a {
            all: unset;
          }
        }

        &:last-child {
          // white-space: nowrap;
          // color: #800080;
          white-space: nowrap;
        }
      }
    }
  }
}
