.list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0%;
  padding-top: 0%;

  .actions-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
