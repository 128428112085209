/* stylelint-disable no-invalid-position-at-import-rule */
@use './variables';

@import '~bulma/sass/utilities/initial-variables.sass';
@import '~bulma/sass/utilities/functions.sass';
@import '~bulma/sass/utilities/derived-variables.sass';
@import '~bulma/sass/utilities/mixins.sass';

@mixin btn-primary {
  &:not([disabled]) {
    background-color: variables.$white;
    border: 1px solid variables.$primary;
    color: variables.$primary;

    &:hover {
      /* stylelint-disable-next-line function-no-unknown */
      background-color: darken($color: variables.$white, $amount: 5);
    }
  }
}

@mixin btn-green {
  &:not([disabled]) {
    background-color: variables.$green;
    border: 1px solid variables.$green;
    color: variables.$white;

    &:hover {
      /* stylelint-disable-next-line function-no-unknown */
      background-color: darken($color: variables.$green, $amount: 5);
    }
  }
}
