@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.divider {
  background-color: variables.$grey;
  border-bottom: 2px solid variables.$grey;
  display: flex;
  flex-direction: row;
  height: 1px;
  justify-content: flex-start;
  margin: 0.5em 0 1.5em;

  span {
    background-color: variables.$primary;
    border-radius: 2px;
    display: block;
    height: 1px;
    width: 55px;
  }

  div {
    background-color: variables.$yellow;
    border-radius: 2px;
    display: block;
    height: 1px;
    width: 8px;
  }
}
