@use '../../../../../abstracts/mixins';
@use '../../../../../abstracts/variables';
@use '../../../../../bases/helpers';

$light-blue: #bbc1cf;

.detail-info {
  margin: 0.5rem 0;

  .detail-info-top {
    display: flex;
    flex-direction: row;

    .detail-info-top-button {
      cursor: default;
      margin: 3px 0 0 6px;
    }
  }

  .detail-info-content {
    /* stylelint-disable-next-line function-no-unknown */
    border-left: 2px solid $light-blue;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 0 0 0 0.5rem;

    &.is-column {
      flex-direction: column;
      gap: 0;

      button.detail-info-content-button {
        align-self: end;
      }
    }

    .detail-info-content-children {
      /* stylelint-disable-next-line function-no-unknown */
      color: lighten($color: variables.$primary, $amount: 15);
      flex: 1;

      b {
        font-weight: bold;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    button.detail-info-content-button {
      align-self: center;
      all: unset;
      cursor: pointer;

      i {
        vertical-align: text-top;
      }
    }
  }
}
