/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/mixins/Depth.Mixins';

.index {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .contact-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    width: 1200px;

    @include mixins.until(variables.$tablet) {
      display: flex;
      flex-direction: column;
    }

    .contact-card {
      @include ms-depth-16;

      background: variables.$white;
      border-radius: 2px;
      box-shadow: $ms-depth-shadow-16;
      padding: 1em;
      width: 500px;

      @include mixins.until(variables.$tablet) {
        flex-direction: column;
        width: auto;
      }

      .contact-header {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;

        .contact-picture {
          background-color: rgb(211, 211, 211);
          border-radius: 50%;
          border-width: 1em;
          height: 70px;
          width: 70px;
        }

        .contact-title {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 10px;
          padding-left: 1em;

          .contact-name {
            color: variables.$primary;
          }

          .contact-function {
            font-style: italic;
          }
        }
      }

      .contact-body {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        .contact-info {
          display: flex;
          flex-direction: row;
          margin-bottom: 0.5rem;

          a {
            margin-left: 0.5rem;
          }

          span {
            margin-left: 0.5rem;
          }
        }

        button {
          margin-bottom: 1rem;
          margin-top: 1rem;
          width: 50%;

          @include mixins.until(variables.$tablet) {
            width: auto;
          }
        }
      }
    }
  }
}
