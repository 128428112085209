.container {
  display: flex;
  height: 640px;
  justify-content: center;
  width: 100%;

  iframe {
    height: 100%;
    width: 1200px;
  }
}
