@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.index {
  @include mixins.until(variables.$tablet) {
    div[class*='column is-three-quarters'] {
      div[class*='column is-one-quarter'] {
        padding: 0 0.75rem;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    div[class*='column is-one-quarter'] {
      padding: 0.75rem 0.75rem 0;
    }
  }

  .reduce-button-container {
    background-color: transparent;
    border-style: none;
    font-style: italic;
    font-weight: bold;
    padding-right: 0;
    padding-top: 0;

    .reduce-button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80px;
    }
  }

  .action-filter-buttons {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-end;

    .separator {
      min-width: 5px;
    }
  }
}
