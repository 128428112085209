/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */

@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.support-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:nth-child(1) {
    text-align: center;
  }
}

.handling-header {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: -11px;
  padding: 11px;
  width: 100%;

  > * {
    text-align: left;

    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      text-align: right;
      width: 100%;
    }
  }
}

.handling-data {
  display: flex;
  flex-direction: row;
  margin-left: 4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  > div {
    min-width: 60px;
    padding-left: 1rem;
    text-align: left;
  }
}

.article-data {
  display: flex;
  flex-direction: row;

  > div {
    text-align: center;

    &:nth-child(1) {
      text-align: left;
      width: 130px;
    }

    &:nth-child(3) {
      width: 70px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      text-align: right;
      width: 70px;
    }

    &:nth-child(2),
    &:nth-child(4) {
      width: 11px;
    }

    &:nth-child(7) {
      text-align: right;
      width: 150px;
    }
  }
}

.merchandise-data {

  // example
}
