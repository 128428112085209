@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

@mixin set-color($color-to-set) {
  /* stylelint-disable-next-line function-no-unknown */
  color: lighten($color: $color-to-set, $amount: 15);
}

.banner {
  @include set-color(variables.$primary);

  font-size: 15px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  padding-top: 0.75rem;
  text-align: center;

  &.is-red {
    @include set-color(#f14668);
  }
}
