@use '../../../../abstracts/mixins';
@use '../../../../abstracts/variables';
@use '../../../../bases/helpers';

.pivot-delivery-times {
  .action-filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .separator {
      min-width: 5px;
    }
  }
}
