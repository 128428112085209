.index {
  div[class*='ms-DetailsRow-cell'] {
    align-self: center;
    text-wrap: wrap;
  }

  .action-filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .separator {
      min-width: 5px;
    }
  }
}
