@use '../../../../../../abstracts/mixins';
@use '../../../../../../abstracts/variables';
@use '../../../../../../bases/helpers';

.delivery-step-file-list {
  li {
    display: flex;
    margin: 0.5rem 0;

    span {
      &:nth-child(2) {
        color: variables.$primary;
        cursor: pointer;
        flex: 1;
        font-weight: bold;
        margin-left: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a {
        all: unset;
      }
    }
  }
}
