@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.address-list {
  width: 100%;

  .row-icon {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;

    > * {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
  }

  .address-display-item {
    display: flex;
    flex-direction: column;
    height: 55px;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 0%;
    padding-top: 0%;
    text-overflow: ellipsis;
    white-space: nowrap;

    b {
      font-size: small;
      font-weight: bold;
    }
  }

  .address-display-item-pins {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    height: 55px;
    justify-content: flex-start;
    padding-bottom: 0%;
    padding-top: 0%;
  }
}
