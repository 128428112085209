@use '../../../../abstracts/mixins';
@use '../../../../abstracts/variables';
@use '../../../../bases/helpers';

.id-client {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  .id-client-top {
    display: flex;
    justify-content: space-between;

    @include mixins.until(variables.$desktop) {
      flex-direction: column;
    }

    > * {
      &:nth-child(1) {
        flex: 1;

        @include mixins.until(variables.$desktop) {
          display: none;
        }
      }

      &:nth-child(2) {
        flex: 2;
      }

      &:nth-child(3) {
        flex: 1;
      }
    }
  }

  .id-client-btns {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;

    @include mixins.until(variables.$desktop) {
      justify-content: center;
      margin-bottom: 1rem;
    }

    .id-client-btns-green-btn {
      @include mixins.btn-green;
    }

    .id-client-btns-blue-btn {
      @include mixins.btn-primary;
    }
  }

  form {
    display: flex;
    flex: 1 1 auto;
  }
}
