@use '../abstracts/variables';
@use '../abstracts/mixins';

@font-face {
  font-family: gotham;
  font-weight: normal;
  src:
    url('/fonts/Gotham-Book.woff') format('woff'),
    url('/fonts/Gotham-Book.woff2') format('woff2'),
    url('/fonts/Gotham-Book.ttf') format('truetype');
}

html {
  background-color: variables.$light-grey;
  color: variables.$black !important;
  margin: 0;
  max-width: 100vw;
  min-height: 100%;
  overflow-x: hidden;
  padding: 0 !important;

  &.is-scroll-disable {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }

  > * {
    font-family:
      gotham,
      'Segoe UI',
      'Segoe UI Web (West European)',
      -apple-system,
      BlinkMacSystemFont,
      Roboto,
      'Helvetica Neue',
      sans-serif !important;
  }

  body {
    height: 100%;
    margin: 0;
    max-width: 100vw;
    min-height: 100%;
    padding: 0;

    a:focus {
      outline: none;
      text-decoration: none;
      user-select: none;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    label:not(.ms-ChoiceField-field):not(.ms-Checkbox-label) {
      color: variables.$dark-primary !important;
    }

    textarea {
      resize: vertical;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ms-Button-label {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ms-Dialog-main {
      overflow: hidden !important;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ms-Pivot {
      @include mixins.until(mixins.$tablet) {
        button {
          &::after {
            content: unset;
          }

          /* stylelint-disable-next-line selector-class-pattern */
          .ms-Pivot-text {
            display: none;
          }
        }
      }
    }
  }
}
