/* stylelint-disable selector-class-pattern */

.ms-TextField {
  box-shadow: none;
  box-sizing: border-box;
  /* stylelint-disable-next-line font-family-no-duplicate-names */
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  margin: 0;
  padding: 0;
  position: relative;

  .ms-TextField-wrapper {
    .ms-TextField-fieldGroup {
      align-items: stretch;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(96, 94, 92);
      border-radius: 2px;
      box-shadow: none;
      box-sizing: border-box;
      cursor: text;
      display: flex;
      flex-direction: row;
      height: 32px;
      margin: 0;
      padding: 0;
      position: relative;

      &:hover {
        border-color: rgb(50, 49, 48);
      }

      &.is-focused {
        border-color: #00375d;
      }

      &.is-disabled {
        background: #f3f2f1;
        /* stylelint-disable-next-line declaration-property-value-disallowed-list */
        border: none;

        * {
          background-color: #f3f2f1;
          cursor: default;
        }
      }

      input {
        background: none transparent;
        /* stylelint-disable-next-line declaration-property-value-disallowed-list */
        border: none;
        border-radius: 0;
        box-shadow: none;
        box-sizing: border-box;
        color: rgb(50, 49, 48);
        /* stylelint-disable-next-line font-family-no-duplicate-names */
        font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        font-weight: 400;
        margin: 0;
        min-width: 0;
        outline: 0;
        // padding: 0 8px;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }

  &.ms-TextField--borderless {
    .ms-TextField-wrapper {
      .ms-TextField-fieldGroup {
        /* stylelint-disable-next-line declaration-property-value-disallowed-list */
        border: none;
      }
    }
  }

  // &.is-required {}

  // &.is-disabled {}

  &.is-error {
    .ms-TextField-wrapper {
      .ms-TextField-fieldGroup {
        border: 1px solid rgb(164, 38, 44);
      }
    }
  }

  .error-message {
    line-height: 1;
    margin-top: 5px;

    span {
      color: rgb(164, 38, 44);
      font-size: 12px;
    }
  }
}
