@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.index {
  @include mixins.until(variables.$tablet) {
    div[class*='column is-three-quarters'] {
      div[class*='column is-one-quarter'] {
        padding: 0 0.75rem;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    div[class*='column is-one-quarter'] {
      padding: 0.75rem 0.75rem 0;
    }
  }

  .filters-card {
    padding-bottom: 0;

    .date-filter {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .header-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 15px;

    button[class*='ms-Button']:first-of-type {
      padding: 0 5px;

      i[class*='ms-Icon'] {
        transform: rotate(45deg);
      }
    }
  }

  .reduce-button-container {
    background-color: transparent;
    border-style: none;
    font-style: italic;
    font-weight: bold;
    padding-right: 0;
    padding-top: 0;

    .reduce-button {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80px;
    }
  }

  .action-filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .separator {
      min-width: 5px;
    }
  }

  .waybills-row {
    all: unset;
    cursor: pointer;

    .transport-list-row-item {
      display: flex;
      flex-direction: column;
      height: 67px;
      justify-content: center;

      b {
        font-size: small;
        font-weight: bold;
      }

      img {
        max-height: 40px;
        width: auto;
      }

      .transport-list-row-item-checked {
        display: flex;
        flex-direction: row;

        > * {
          &:first-child {
            margin-right: 5px;
            transform: translateY(-2px);
          }
        }
      }

      .transport-list-row-item-date {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        img {
          margin-left: 5px;
          max-height: 30px;
          width: auto;
        }

        span {
          align-self: center;
        }
      }

      button {
        cursor: initial;
      }
    }
  }
}
