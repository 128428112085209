@use '../../../../../../abstracts/mixins';
@use '../../../../../../abstracts/variables';
@use '../../../../../../bases/helpers';

.tracking-link-modal {
  background-color: rgb(74, 199, 199);

  .emails {
    background-color: rgb(170, 170, 50);
    display: flex;
    flex-direction: row;
  }

  // li {
  //   display: flex;
  //   justify-content: space-between;
  //   margin: 0.5rem 0;

  //   span {
  //     &:first-child {
  //       color: variables.$primary;
  //       cursor: pointer;
  //       font-weight: bold;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       white-space: nowrap;
  //     }

  //     a {
  //       all: unset;
  //     }
  //   }
  // }
}
