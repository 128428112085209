@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.invoice-data {
  ul.invoice-data-numbers {
    li {
      display: flex;
      justify-content: space-between;
    }
  }

  ul.invoice-data-files {
    li {
      display: flex;
      justify-content: space-between;

      > span:first-child {
        color: variables.$primary;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
