/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.transport-data {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .transport-data-address {
    margin-bottom: 0.3rem;

    b {
      font-weight: bold;
    }
  }

  .transport-data-dates {
    background-color: rgba($color: #e6f5ff, $alpha: 40%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.5rem 0.5rem 0;

    .transport-data-dates-title {
      color: variables.$primary;
    }

    .transport-data-dates-content {
      color: variables.$primary;
      padding-left: 1rem;
      position: relative;

      &::before {
        background-color: variables.$primary;
        content: '';
        height: 100%;
        left: 0.25rem;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }
  }
}
