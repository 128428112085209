@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.transport-card-list {
  hr {
    margin: 0.5rem 0;
  }

  .transport-card-list-row {
    >div {
      &:first-child {
        display: flex;
        justify-content: space-between;

        span {
          &:first-child {
            color: variables.$primary;
            font-weight: bold;
          }
        }
      }

      &:last-child {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0.25rem;
        position: relative;

        > div.transport-card-list-row-column {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-start;

          > span {
            &:first-child {
              font-weight: bold;
            }
          }

          &:first-child {
            $arrow-size: 3rem;

            position: relative;

            span {
              padding-right: calc($arrow-size / 2);
            }
            // qs
            &::after {
              bottom: 0;
              /* stylelint-disable-next-line function-no-unknown */
              color: lighten($color: variables.$primary, $amount: 55);
              content: '⇨';
              display: flex;
              flex-direction: column;
              font-size: $arrow-size;
              justify-content: center;
              position: absolute;
              right: - calc($arrow-size / 2);
              top: 0;
            }
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}
