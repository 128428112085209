/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.folder-data {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  min-width: 450px;

  .folder-data-agency {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    // .folder-data-agency-title{}

    .folder-data-agency-content {
      color: variables.$primary;
      padding-left: 1rem;
      position: relative;

      &::before {
        background-color: variables.$primary;
        content: '';
        height: 100%;
        left: 0.25rem;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }
  }

  .folder-data-references {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: flex-start;
    // .folder-data-references-title{}

    .folder-data-references-content {
      padding-left: 1rem;
      position: relative;

      &::before {
        background-color: variables.$primary;
        content: '';
        height: 100%;
        left: 0.25rem;
        position: absolute;
        top: 0;
        width: 1px;
      }

      ul {
        li {
          display: flex;
          justify-content: space-between;

          span {
            // &:first-child {}

            &:last-child {
              color: variables.$primary;
            }
          }
        }
      }
    }
  }
}
