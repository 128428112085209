/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */

@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';
@use 'sass:math';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~bulma/sass/utilities/derived-variables.sass';

$error-color: #f14668;
$warning-color: #f8c115;
$normal-color: #77c7ff;

.status-data {
  > ul {
    li.status-data-element {
      display: flex;
      justify-content: space-between;
      padding: 0 2rem 1rem 3rem;
      position: relative;

      &.is-error {
        &::before {
          background-color: $error-color;
        }

        &::after {
          border-color: $error-color;
        }

        * {
          color: $error-color !important;

          i {
            color: $error-color;
          }
        }
      }

      &.is-warning {
        &::before {
          background-color: $warning-color;
        }

        &::after {
          border-color: $warning-color;
        }

        * {
          color: $warning-color !important;

          i {
            color: $warning-color;
          }
        }
      }

      &:last-child {
        overflow: hidden;

        &::before {
          display: none;
        }
      }

      &::before {
        /* stylelint-disable-next-line function-no-unknown */
        background-color: $normal-color;
        content: '';
        height: 100%;
        left: 10px;
        position: absolute;
        top: 0;
        top: 5px;
        width: 2px;
      }

      &::after {
        $size: 4px;

        /* stylelint-disable-next-line function-no-unknown */
        border: $size solid $normal-color;
        border-radius: 50%;
        content: '';
        height: $size * 2;
        /* stylelint-disable-next-line function-no-unknown */
        left: 7px;
        position: absolute;
        top: 5px;
        width: $size * 2;
      }

      b {
        font-weight: bold;
      }

      i {
        color: variables.$dark-grey;
        font-style: italic;
      }

      .status-data-element-icon {
        font-style: inherit;
        left: 23px;
        position: absolute;
      }

      > *:last-child {
        /* stylelint-disable-next-line function-no-unknown */
        color: $normal-color;
        margin-left: 0.5rem;
        min-width: 45px;
      }
    }
  }
}
