@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.order-list {
  width: 100%;

  .order-list-row {
    >* {
      // background-color: rgb(221, 230, 226);

      .order-list-row-item {
        display: flex;
        flex-direction: column;
        height: 67px;
        justify-content: center;

        b {
          font-size: small;
          font-weight: bold;
        }

        img {
          max-height: 40px;
          width: auto;
        }
      }
    }
  }
}
