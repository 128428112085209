/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.company-data {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .company-data-content {
    display: flex;
    justify-content: space-between;

    .company-data-content-img {
      flex: 1;
      margin: auto;
      text-align: center;

      &.is-visible {
        img {
          opacity: 1;
        }
      }

      img {
        filter: drop-shadow(1px 1px 1px variables.$black);
        height: auto;
        max-width: 50px;
        opacity: 0;
        transition: 0.5s opacity;
        width: auto;
      }
    }

    .company-data-content-desc {
      color: variables.$primary;
      flex: 2;

      b {
        font-weight: bold;
      }

      i {
        font-style: italic;
      }
    }
  }

  .company-data-contact {
    color: variables.$primary;
    display: inline-block;
    text-align: right;
    width: 100%;

    a {
      color: unset;
    }
  }
}
