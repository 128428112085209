@use '../../../../../abstracts/mixins';
@use '../../../../../abstracts/variables';
@use '../../../../../bases/helpers';

.delivery-step {
  display: grid;
  flex: 1 1 auto;
  gap: 1em 1em;
  grid-template-areas:
    'delivery-step-left delivery-step-right'
    // 'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right'
    'delivery-step-left delivery-step-right';
  grid-template-columns: 1fr 1fr;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: repeat(8, 1fr);
  // height: 1000px;

  @include mixins.until(variables.$widescreen) {
    grid-template-areas:
      'delivery-step-left'
      'delivery-step-right';
    grid-template-columns: 1fr;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: repeat(2, minmax(50px, auto));
  }

  .delivery-step-left {
    display: grid;
    gap: 1em 1em;
    grid-area: delivery-step-left;
    grid-template-areas:
      'delivery-step-left-address delivery-step-left-address delivery-step-left-complement'
      // 'delivery-step-left-address delivery-step-left-address delivery-step-left-complement'
      'delivery-step-left-address delivery-step-left-address delivery-step-left-complement'
      'delivery-step-left-address delivery-step-left-address delivery-step-left-complement'
      'delivery-step-left-instructions delivery-step-left-instructions delivery-step-left-instructions'
      'delivery-step-left-instructions delivery-step-left-instructions delivery-step-left-instructions'
      'delivery-step-left-instructions delivery-step-left-instructions delivery-step-left-instructions'
      'delivery-step-left-date delivery-step-left-date delivery-step-left-date'
      'delivery-step-left-date delivery-step-left-date delivery-step-left-date';
    grid-template-columns: 1fr 1fr 1fr;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: repeat(8, 1fr);

    @include mixins.until(variables.$tablet) {
      grid-template-areas:
        'delivery-step-left-address'
        'delivery-step-left-complement'
        'delivery-step-left-instructions'
        'delivery-step-left-date';
      grid-template-columns: 1fr;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-rows: repeat(4, minmax(50px, auto));
    }
  }

  .delivery-step-left-address {
    grid-area: delivery-step-left-address;
  }

  .delivery-step-left-complement {
    grid-area: delivery-step-left-complement;
  }

  .delivery-step-left-instructions {
    grid-area: delivery-step-left-instructions;
  }

  .delivery-step-left-date {
    grid-area: delivery-step-left-date;
  }

  .delivery-step-right {
    display: grid;
    gap: 1em 1em;
    grid-area: delivery-step-right;
    grid-template-areas:
      'delivery-step-right-content delivery-step-right-content delivery-step-right-content'
      // 'delivery-step-right-content delivery-step-right-content delivery-step-right-content'
      'delivery-step-right-content delivery-step-right-content delivery-step-right-content'
      'delivery-step-right-content delivery-step-right-content delivery-step-right-content'
      'delivery-step-right-content delivery-step-right-content delivery-step-right-content'
      'delivery-step-right-content delivery-step-right-content delivery-step-right-content'
      'delivery-step-right-documents delivery-step-right-documents delivery-step-right-documents'
      'delivery-step-right-documents delivery-step-right-documents delivery-step-right-documents'
      'delivery-step-right-documents delivery-step-right-documents delivery-step-right-documents';
    grid-template-columns: 1fr 1fr 1fr;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: repeat(8, 1fr);
  }

  .delivery-step-right-content {
    grid-area: delivery-step-right-content;

    > div[class*='card'] {
      div[class*='columns'] {
        display: flex;

        @include mixins.until(variables.$tablet) {
          flex-direction: column;
        }
      }
    }

    .btns-blue-btn {
      @include mixins.btn-primary;

      display: flex;
      justify-content: center;
      width: 100%;
    }

    .delivery-step-right-content-content {
      max-height: 568px;
      overflow: auto;
      overflow-x: inherit;
    }
  }

  .delivery-step-right-documents {
    grid-area: delivery-step-right-documents;

    > div[class*='card'] {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      div[class*='columns'] {
        height: 100%;
        max-height: 266px;
        overflow: auto;
      }
    }
  }

  .delivery-step-left-address,
  .delivery-step-left-complement,
  .delivery-step-left-instructions,
  .delivery-step-left-date,
  .delivery-step-right-content,
  .delivery-step-right-documents {
    > * {
      height: 100%;
    }
  }
}
