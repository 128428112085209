@use '../../../../../abstracts/mixins';
@use '../../../../../abstracts/variables';
@use '../../../../../bases/helpers';

.pickup-step {
  display: grid;
  flex: 1 1 auto;
  gap: 1em 1em;
  grid-template-areas:
    'pickup-step-address pickup-step-date'
    'pickup-step-address pickup-step-instruction';
  grid-template-columns: 1fr 1fr;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: 1fr 1fr;

  @include mixins.until(variables.$desktop) {
    grid-template-areas:
      'pickup-step-address'
      'pickup-step-date'
      'pickup-step-instruction';
    grid-template-columns: 1fr;
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: repeat(3, minmax(50px, auto));
  }

  .pickup-step-address {
    grid-area: pickup-step-address;
  }

  .pickup-step-date {
    grid-area: pickup-step-date;
  }

  .pickup-step-instruction {
    grid-area: pickup-step-instruction;
  }

  .pickup-step-address,
  .pickup-step-date,
  .pickup-step-instruction {
    > * {
      height: 100%;
    }
  }
}
