@use '../../../../../abstracts/mixins';
@use '../../../../../abstracts/variables';
@use '../../../../../bases/helpers';

$green: rgb(140, 192, 140);
$red: #db9696;
$grey: #adadad;

.week-days {
  display: flex;
  flex-direction: row;

  > button {
    background-color: $red;
    border: 0;
    border-radius: 6px;
    color: inherit;
    /* stylelint-disable-next-line function-no-unknown */
    color: darken($color: $red, $amount: 40);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font: inherit;
    font-size: 11px;
    height: 28px;
    justify-content: center;
    margin-left: 7px;
    outline: none;
    text-align: center;
    vertical-align: auto;
    width: 42px;

    &.is-open {
      background-color: $green;
      /* stylelint-disable-next-line function-no-unknown */
      color: darken($color: $green, $amount: 40);
    }

    &.is-undefined {
      background-color: $grey;
      /* stylelint-disable-next-line function-no-unknown */
      color: darken($color: $grey, $amount: 40);
    }
  }
}
