/* stylelint-disable no-descending-specificity */
@use '../../../../../../abstracts/mixins';
@use '../../../../../../abstracts/variables';
@use '../../../../../../bases/helpers';
/* stylelint-disable declaration-property-value-disallowed-list */

@mixin warning-field($warn-color: variables.$red) {
  > div[class*='column'] {
    &.is-warned {
      div[class*='ms-TextField-fieldGroup'] {
        border: 1;
        border-color: $warn-color;

        div[class*='ms-TextField-suffix'] {
          background: rgba($warn-color, 30%);
        }
      }
    }
  }
}

@mixin size-input {
  display: flex;

  > div:nth-child(1) {
    div[class*='ms-TextField-fieldGroup'] {
      border-bottom-right-radius: 0;
      border-right: none;
      border-top-right-radius: 0;

      &::after {
        border-right: none;
      }

      &::before {
        background: rgb(96, 94, 92);
        content: '';
        height: 75%;
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px;
      }
    }
  }

  > div:nth-child(2) {
    div[class*='ms-TextField-fieldGroup'] {
      border-left: none;
      border-radius: 0;
      border-right: none;

      &::after {
        border-left: none;
        border-right: none;
      }

      &::before {
        background: rgb(26, 151, 57);
        content: '';
        height: 75%;
        position: absolute;
        right: 0;
        top: 4px;
        width: 1px;
      }
    }
  }

  > div:nth-child(3) {
    div[class*='ms-TextField-fieldGroup'] {
      border-left: none;
      border-radius: 0;
      border-right: none;

      &::after {
        border-left: none;
        border-right: none;
      }
    }
  }

  > div:nth-child(4) {
    div[class*='ms-TextField-fieldGroup'] {
      border-bottom-left-radius: 0;
      border-left: none;
      border-top-left-radius: 0;

      &::after {
        border-left: none;
      }
    }
  }
}

// @mixin weight-input {
//   display: flex;

//   > div:nth-child(1) {
//     div[class*='ms-TextField-fieldGroup'] {
//       border-bottom-right-radius: 0;
//       border-right: none;
//       border-top-right-radius: 0;

//       &::after {
//         border-left: none;
//         border-right: none;
//       }
//     }
//   }

//   > div:nth-child(2) {
//     div[class*='ms-TextField-fieldGroup'] {
//       border-bottom-left-radius: 0;
//       border-left: none;
//       border-top-left-radius: 0;

//       &::after {
//         border-left: none;
//       }
//     }
//   }
// }

.delivery-step-content {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }

  .delivery-step-content-main {
    margin-bottom: 1rem;

    .delivery-step-content-main-support {
      gap: 0.25rem;
      margin-bottom: 0.5rem;

      > div[class*='column'] {
        &:nth-child(1) {
          flex: 2;
        }

        &:nth-child(2),
        &:nth-child(3) {
          flex: 1;
        }
      }
    }

    .delivery-step-content-main-data {
      @include warning-field;

      gap: 0.25rem;
      margin-bottom: 0.5rem;

      .delivery-step-content-main-size {
        @include size-input;

        flex: 2;
      }
    }

    .add-article-container {
      > div[class*='column'] {
        &:nth-child(1) {
          flex: 0.5;
        }

        &:nth-child(2) {
          flex: 9.5;

          .delivery-step-content-main-sub-add-btn {
            all: unset;
            cursor: pointer;
            margin-left: 0.25rem;

            i {
              vertical-align: text-bottom;
            }
          }
        }
      }
    }

    .delivery-step-content-main-sub {
      margin-bottom: 1rem;

      .delivery-step-content-sub-parcel {
        display: flex;
        gap: 0.25rem;
        margin-bottom: 0.5rem;

        > div[class*='column'] {
          &:nth-child(1),
          &:nth-child(2) {
            flex: 1;
          }
        }
      }

      .delivery-step-content-sub-main-article {
        gap: 0.25rem;
        margin-bottom: 0.5rem;
        padding-left: 2rem;

        > div[class*='column'] {
          &:nth-child(1) {
            width: 100%;
          }

          &:nth-child(3) {
            max-width: 120px;
            min-width: 120px;
          }

          &:nth-child(4) {
            max-width: 90px;
            min-width: 90px;
          }
        }
      }

      .delivery-step-content-sub-main-data {
        @include warning-field($warn-color: variables.$primary);

        gap: 0.25rem;
        margin-bottom: 0.5rem;
        padding-left: 2rem;
        position: relative;

        .calc-footage {
          display: flex;
          flex-direction: row;

          .info-tool-tip {
            cursor: default;
            display: none;

            &.show-tooltip {
              display: inline;
            }

            > div[class*='ms-TooltipHost'] {
              margin-right: 3px;

              > i {
                align-content: center;
                color: variables.$primary;
                height: 100%;
              }
            }
          }
        }

        > div[class*='column'] {
          &:nth-child(1) {
            @include size-input;

            flex: 1.5;
          }
        }

        .delivery-step-content-dimensions-warning {
          display: none;
          left: 2px;
          position: absolute;
          top: 1px;

          &.is-visible {
            display: inline;
          }
        }
      }
    }
  }
}
