/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../abstracts/mixins';
@use '../abstracts/variables';
@use '../bases/helpers';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/mixins/Depth.Mixins';

nav[class*='navbar'] {
  @include ms-depth-16;

  background: variables.$primary;

  *[class*='lang-selector'] {
    background: transparent !important;
    color: inherit;

    @include mixins.until(variables.$desktop) {
      margin: 10px 0;
    }
  }

  [class*='navbar-brand'] {
    span {
      color: variables.$white;
    }

    [class*='navbar-item'] {
      span {
        color: variables.$white;
        margin-left: 13px;
      }
    }
  }

  [class*='navbar-end'] {
    i {
      color: variables.$white !important;
    }

    [class*='navbar-item'] {
      &.is-tab:hover {
        border-bottom-color: variables.$primary !important;
      }

      &:hover {
        border-bottom-color: variables.$primary !important;
        color: variables.$primary !important;
      }

      &:focus-within {
        background-color: unset;
      }
    }
  }

  a:hover {
    i {
      border-bottom-color: variables.$white !important;
      color: variables.$white !important;
    }
  }

  [class*='navbar-menu'] {
    @include mixins.until(variables.$desktop) {
      left: 0;
      position: absolute;
      right: 0;
    }

    [class*='navbar-start'] {
      i {
        color: variables.$primary !important;
        vertical-align: bottom;
      }

      [class*='navbar-link'] {
        &:hover {
          background: unset !important;
          color: unset !important;
          cursor: auto;
        }
      }

      a:hover {
        background: variables.$light-grey;
        color: variables.$primary !important;
      }
    }
  }
}

.main {
  // height: 100vh;
  background-color: variables.$light-grey;
  box-shadow: inset $ms-depth-shadow-8;

  .main-body {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1600px;
    min-height: calc(100vh - #{variables.$nav-bar-height});
    padding: 10px 35px;

    @include mixins.until(variables.$tablet) {
      padding: 5px 10px;
    }

    @include mixins.until(variables.$desktop) {
      width: 100% !important;
    }

    > [class*='ms-MessageBar'] {
      @include ms-depth-16;
    }

    [class*='ms-Breadcrumb'][role*='navigation'] {
      margin: 0 0 13px;

      i {
        font-size: 12px;
        margin: 0 auto -2px;
      }
    }

    [class*='ms-Breadcrumb']:not([class*='ms-Breadcrumb-listItem']):not([class*='ms-Breadcrumb-item']):not([class*='ms-Breadcrumb-chevron']) {
      ol {
        margin: 0;
      }

      li {
        span {
          cursor: auto;
        }

        a > * {
          cursor: pointer;
        }

        + li {
          margin-top: 0 !important;
        }

        &:not(:last-child) {
          // span,
          // button {
          //   color: $white;
          // }
          button:hover {
            background: initial;
          }
        }

        &:last-child {
          span,
          button {
            color: variables.$darker-primary;
            text-shadow: 0 0 2px rgba(255, 255, 255, 40%);
          }
        }
      }
      // .ms-Breadcrumb-chevron {
      //   color: $white;
      // }
      [class*='ms-Breadcrumb-overflow'] {
        > button {
          &:active,
          &:hover,
          &.is-expanded {
            background: rgba(234, 234, 234, 20%) !important;
          }
        }
      }
    }

    footer {
      color: variables.$primary;
      margin-top: auto;
      padding-top: 1rem;
      text-align: center;

      > a {
        color: variables.$primary;
      }
    }
  }
}
