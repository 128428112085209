@use '../abstracts/mixins';
@use '../abstracts/variables';

.index {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 215px);
  justify-content: space-between;

  @include mixins.until(variables.$tablet) {
    height: auto;
  }

  > * {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;

    &[class*='columns'] {
      flex: 1;
      height: 100%;
      overflow: hidden;

      @include mixins.until(variables.$tablet) {
        display: flex;
        flex-direction: column;
        overflow: unset;
      }

      > div[class*='column'] {
        max-height: 100%;

        > div { // Card
          height: 100%;

          .card-top-container {
            max-height: calc(100% - 28px - 2em);

            .transport-list-row {
              >* {
                .transport-list-row-item {
                  display: flex;
                  flex-direction: column;
                  height: 67px;
                  justify-content: center;

                  b {
                    font-size: small;
                    font-weight: bold;
                  }

                  img {
                    max-height: 40px;
                    width: auto;
                  }
                }
              }
            }
            // overflow-y: auto;
            // scrollbar-width: thin;
          }
        }
      }
    }

    .card-bottom-container {
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: flex-start;
      max-height: calc(100% - 28px - 2em);
      overflow-x: auto;
      padding: 0.5rem 1rem 1rem;
      scrollbar-width: thin;

      @include mixins.until(1100px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
