@use '../../../../../../abstracts/mixins';
@use '../../../../../../abstracts/variables';
@use '../../../../../../bases/helpers';

.label-modal {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
