@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.department-list {
  width: 100%;

  div[class*='ms-DetailsRow-cell'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  div[class*='ms-DetailsHeader'] {
    height: 50px;
    padding-top: 0;

    span[class*='ms-DetailsHeader-cellTitle'] {
      height: 100%;

      span[class*='ms-DetailsHeader-cellName'] {
        display: flex;
        flex-direction: column;
        height: max-content;
        height: 100% !important;
        justify-content: center;
        line-height: 21px;
        text-wrap: wrap;
        width: 100%;
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-end;
  }
}
