@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/mixins/Depth.Mixins';

.card {
  @include ms-depth-16;

  background: variables.$white;
  border-radius: 2px;
  box-shadow: $ms-depth-shadow-16;
  overflow: hidden;
  padding: 20px;
  position: relative;
  word-break: break-word;

  i {
    vertical-align: bottom;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
