/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.maintenance-portal-info {
  display: flex;
  flex-direction: column;
  height: 80%;
  height: 500px;
  justify-content: space-around;

  span {
    font-size: 1rem;
    text-align: center;
  }
}
