@use '../../../../../abstracts/mixins';
@use '../../../../../abstracts/variables';
@use '../../../../../bases/helpers';

.confirmation-step {
  height: 100%;
  width: 100%;
  // .confirmation-banner {
  //   /* stylelint-disable-next-line function-no-unknown */
  //   color: lighten($color: variables.$primary, $amount: 15);
  //   font-size: 15px;
  //   font-weight: bold;
  //   margin-bottom: 0.5rem;
  //   padding: 0.5rem;
  //   padding-top: 0.75rem;
  //   text-align: center;
  // }

  .confirmation-summary {
    // background-color: rgb(188, 47, 47);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;

    .delivery-action-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .delivery-address-data-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 0%;
      padding-top: 0%;

      b {
        font-size: small;
        font-weight: bold;
      }
    }

    .confirmation-step-buttons {
      display: flex;
      gap: 0.5rem;
      margin-top: auto;

      > *:last-child {
        margin-left: auto;
      }
    }
  }

  .marchandise-support-data {
    flex: 0.9;
    max-width: '555px';
    table-layout: fixed;

    tr {
      display: flex;
      flex-direction: space-between;

      > td {
        text-align: center;

        &:nth-child(1) {
          flex: 1.1;
          text-align: left;
        }

        &:nth-child(2),
        &:nth-child(4) {
          flex: 0.1;
          margin-right: 1rem;
        }

        &:nth-child(3) {
          flex: 0.5;
          text-align: left;
        }

        &:nth-child(5),
        &:nth-child(6) {
          flex: 0.5;
        }

        &:nth-child(7) {
          flex: 0.8;
        }

        &:last-child {
          width: 100px;

          p {
            overflow: hidden;
          }
          // align-items: center;
          // display: flex;
          // flex: 1;
          // height: 200px; /* hauteur de votre conteneur */
          // justify-content: center;
          // text-align: center;
          // width: 60px;
        }
      }
    }

    &.is-jetfreeze {
      tr {
        > td {
          // &:nth-child(2) {
          //   flex: 0.1;
          //   margin-right: 1rem;
          // }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            flex: 0.5;
          }
        }
      }
    }
  }

  .marchandise-article-data {
    display: flex;
    flex-direction: space-between;
    margin-left: 4rem;
    width: 85%;

    > div {
      text-align: center;

      &:nth-child(1) {
        flex: 0.9;
        text-align: left;
      }

      &:nth-child(2),
      &:nth-child(4) {
        flex: 0.1;
      }

      &:nth-child(3) {
        flex: 0.5;
        text-align: left;
      }

      &:nth-child(5),
      &:nth-child(6) {
        flex: 0.5;
      }

      &:nth-child(7) {
        flex: 0.8;
      }

      &:nth-child(8) {
        flex: 1;
      }
    }
  }
}
