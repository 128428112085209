/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 10px 0;

  @include mixins.until(1000px) {
    flex-direction: column;

    > div:first-child {
      margin-bottom: 10px;
    }
  }

  .total {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .pagination {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;

    > .item {
      height: 30px;
      margin-left: 10px;
      width: 40px;

      > button {
        height: 100%;
        width: 100%;
      }
    }
  }
}
