@use '../../../../../../abstracts/mixins';
@use '../../../../../../abstracts/variables';
@use '../../../../../../bases/helpers';

.delivery-step-advanced-file-input {
  .delivery-step-advanced-file-input-url {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    outline: 1px solid variables.$dark-primary;
    outline-offset: -5px;
    padding: 1rem;

    > span {
      text-align: center;

      i {
        font-size: 1.5rem;
      }
    }

    .delivery-step-advanced-file-input-url-field {
      display: flex;
      width: 100%;

      > *:first-child {
        width: 100%;
      }
    }
  }

  .is-divider {
    border-top: 0.1rem solid #dbdbdb;
    display: block;
    height: 0.1rem;
    margin: 0.25rem 0;
    position: relative;
    text-align: center;

    &::after {
      background: #fff;
      color: #b5b5b5;
      content: attr(data-content);
      display: inline-block;
      font-size: 0.75rem;
      line-height: 1rem;
      margin: 0.55rem 0;
      padding: 0 0.55rem;
      text-align: center;
      transform: translateY(-1.1rem);
    }
  }

  .delivery-step-advanced-file-input-file {
    background: variables.$white;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    outline: 1px solid variables.$dark-primary;
    outline-offset: -5px;
    padding: 1rem;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

    &.is-dragging {
      background: variables.$light-grey;
      outline-offset: -10px;
    }

    > span {
      text-align: center;

      i {
        font-size: 1.5rem;
      }
    }

    input[type='file'] {
      display: none;
    }
  }
}
