/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.address-modal-map {
  height: 100%;
  position: relative;

  @include mixins.until(variables.$desktop) {
    > *:first-child {
      min-height: 700px !important;
    }

    .address-modal-map-start {
      margin: 0.5rem auto !important;
      width: 95% !important;
    }

    .address-modal-map-infos {
      margin: 0.5rem auto !important;
      width: 95% !important;

      > span {
        text-align: center;
      }

      .address-modal-map-infos-columns {
        display: flex;
        justify-content: center;
      }
    }
  }

  .address-modal-map-shadow {
    background: linear-gradient(0deg, #4b5861 22%, rgba(0, 55, 93, 0%) 85%);
    bottom: 0;
    height: 30%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
  }

  .address-modal-slider {
    bottom: 0;
    padding-right: 0.5rem;
    position: absolute;
    right: 0;
  }

  .address-modal-map-start {
    /* stylelint-disable-next-line function-no-unknown */
    background-color: lighten($color: #d13438, $amount: 40);
    border-radius: 0.5rem;
    bottom: 0;
    left: 0;
    margin: 1.5rem auto;
    padding: 1rem;
    position: absolute;
    right: 0;
    width: 75%;

    > span {
      color: #d13438;
    }

    button {
      display: block;
      margin: 0 auto;

      span {
        font-weight: 400;
      }
    }
  }

  .address-modal-map-infos {
    background-color: #fff;
    border-radius: 0.5rem;
    bottom: 0;
    left: 0;
    margin: 1.5rem auto;
    padding: 1rem;
    position: absolute;
    right: 0;
    width: 75%;

    > span {
      color: variables.$primary;
      display: block;
      padding-bottom: 0.5rem;
    }

    .address-modal-map-infos-columns {
      display: flex;
      gap: 0.25rem;

      > div {
        &:last-child {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }
      }
    }
  }
}
