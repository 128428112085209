.index {
  .prices {
    div[class*='ms-DetailsRow'] {
      &:hover {
        background: transparent !important;
      }
    }

    div[class*='ms-DetailsRow-cell'] {
      align-self: center;
      height: 100%;
      text-wrap: wrap;
    }

    .is-grey {
      div[class*='ms-DetailsRow-cell']:not(:last-child) {
        background: #f3f2f1 !important;
      }

      &:hover {
        div[class*='ms-DetailsRow-cell']:not(:last-child) {
          background-color: #d8d7d7 !important;
        }

        div[class*='ms-DetailsRow-cell']:last-child {
          background-color: #f3f2f1 !important;
        }
      }
    }

    .price-list {
      padding-top: 5px;
    }
  }
}
