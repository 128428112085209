.choose-order-type {
  display: grid;
  gap: 2rem;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  padding-top: 1rem;

  .choose-order-type-button {
    display: flex;
    flex-direction: row;
    width: 180px;

    img {
      width: 80px;
    }

    span {
      display: flex;
      flex-direction: column;
      font-size: medium;
      font-weight: bold;
      height: 100%;
      justify-content: center;
    }
  }

  .choose-order-type-button:hover {
    cursor: pointer;
  }
}
