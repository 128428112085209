/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.address-modal {
  display: flex;
  justify-content: space-between;
  width: 1100px;

  @include mixins.until(variables.$widescreen) {
    width: 900px;
  }

  @include mixins.until(variables.$desktop) {
    flex-direction: column;
    width: 100%;

    div[class*='columns'] div[class*='column'] {
      padding: 0.25rem 0.75rem;
    }

    > * {
      &:first-child,
      &:last-child {
        padding: 0 !important;
      }
    }

    span[class*='ms-Button-label'] {
      white-space: initial;
    }
  }

  .address-modal-title {
    color: variables.$dark-grey !important;
    display: block;
    font-weight: 600;
    overflow-wrap: break-word;
    padding: 5px 0;
  }

  .address-modal-subtitle {
    color: variables.$dark-grey !important;
    display: block;
    font-weight: 600;
    overflow-wrap: break-word;
    padding: 5px 0;
  }

  .address-modal-info {
    /* stylelint-disable-next-line function-no-unknown */
    background-color: lighten($color: variables.$primary, $amount: 75);
    border-radius: 0.5rem;
    color: variables.$primary;
    display: block;
    margin: 0.5rem 0;
    padding: 1rem;
    white-space: pre-wrap;
  }

  .address-modal-info-warning {
    /* stylelint-disable-next-line function-no-unknown */
    background-color: lighten($color: variables.$yellow, $amount: 40);
    border-radius: 0.5rem;
    color: variables.$primary;
    display: block;
    margin: 1rem 0 0;
    margin-top: 0;
    padding: 1rem;
    white-space: pre-wrap;
  }

  .address-modal-addresstype-container {
    display: flex;
    margin-bottom: 1rem;

    .address-modal-addresstype {
      border: 1px solid variables.$dark-grey;
      border-radius: 1rem;
      color: variables.$dark-grey;
      cursor: pointer;
      margin: 0 0.5rem 0 0;
      padding: 0.25rem 0.75rem;
      user-select: none;

      &:hover {
        /* stylelint-disable-next-line function-no-unknown */
        border-color: darken($color: variables.$dark-grey, $amount: 20);
        /* stylelint-disable-next-line function-no-unknown */
        color: darken($color: variables.$dark-grey, $amount: 20);
      }

      &.is-active {
        /* stylelint-disable-next-line function-no-unknown */
        background-color: lighten($color: variables.$primary, $amount: 75);
        color: variables.$primary;

        &::after {
          content: '×';
          font-size: 1.15rem;
          font-weight: bold;
          line-height: 1rem;
          margin: 0 0 0 0.5rem;
          vertical-align: text-bottom;
        }
      }

      &.is-disable {
        /* stylelint-disable-next-line function-no-unknown */
        background-color: lighten($color: variables.$dark-grey, $amount: 45);
        border: 1px solid variables.$dark-grey;
        color: variables.$dark-grey;
        pointer-events: none;
      }
    }
  }

  > * {
    flex: 1;

    &:first-child {
      padding: 0 0.5rem 0 0;

      div[class*='columns'] {
        margin-bottom: 0 !important;
      }
    }

    &:last-child {
      padding: 0 0 0 0.5rem;
    }

    // &:is(form) {
    //   display: flex;
    //   flex-direction: column;

    //   > div:last-child {
    //     margin-top: auto;
    //   }
    // }
  }

  .address-modal-footer-buttons {
    &.is-validation {
      span[class*='ms-Dialog-action']:first-child {
        margin-right: auto;

        button {
          color: variables.$red;
        }
      }
    }
  }
}
