@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~bulma/sass/utilities/derived-variables.sass';

.anomalies-data {
  ul.anomalies-data-list {
    li {
      display: flex;
      justify-content: space-between;
      margin: 0.25rem 0;

      div[class*='ms-MessageBar'] {
        i {
          color: $red;
        }

        span[class*='ms-MessageBar-innerText'] {
          width: 100%;

          span {
            color: $red;
            display: flex;
            justify-content: space-between;

            > span:last-child {
              // color: variables.$primary;
              // cursor: pointer;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
