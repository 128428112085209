@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.client-list {
  width: 100%;

  .client-list-row {
    height: 67px;

    img {
      max-height: 40px;
      max-width: 40px;
    }
  }
}
