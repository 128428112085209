/* stylelint-disable-next-line block-no-empty */
.index {
  .action-filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .separator {
      min-width: 5px;
    }
  }

  div[class*='ms-DetailsList'] {
    div[class*='ms-DetailsHeader'] {
      height: 50px;
      padding-top: 0;

      span[class*='ms-DetailsHeader-cellTitle'] {
        height: 100%;

        span[class*='ms-DetailsHeader-cellName'] {
          display: flex;
          flex-direction: column;
          height: max-content;
          height: 100% !important;
          height: 100%;
          justify-content: center;
          line-height: 21px;
          text-wrap: wrap;
          width: 100%;
        }
      }
    }

    div[class*='ms-DetailsRow-cell'] {
      display: flex;
      flex-direction: column;
      height: 55px;
      justify-content: center;
      overflow: hidden;
      padding-bottom: 0%;
      padding-top: 0%;
      text-overflow: ellipsis;
      white-space: nowrap;

      b {
        font-size: small;
        font-weight: bold;
      }
    }
  }
}
