@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

.id {
  .id-body {
    display: flex;
    flex: 1 1 auto;

    .id-body-content {
      display: grid;
      flex: 1 1 auto;
      gap: 1em 1em;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-template-areas:
        'id-body-pickup id-body-status id-body-status id-body-merchandise id-body-merchandise'
        'id-body-delivery id-body-status id-body-status id-body-merchandise id-body-merchandise'
        'id-body-footer id-body-footer id-body-footer id-body-footer id-body-footer';
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-rows: 1fr 1fr 1fr;

      @include mixins.until(variables.$widescreen) {
        grid-template-areas:
          'id-body-pickup'
          'id-body-delivery'
          'id-body-status'
          'id-body-merchandise'
          'id-body-footer';
        grid-template-columns: 1fr;
        /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
        grid-template-rows: repeat(5, minmax(50px, auto));
      }

      > * {
        > div[class*='card'] {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: flex-start;
        }
      }

      .id-body-status {
        grid-area: id-body-status;

        > div[class*='card'] {
          >*:last-child {
            max-height: 409px;
            overflow: auto;

            @include mixins.until(variables.$widescreen) {
              max-height: inherit;
            }
          }
        }
      }

      .id-body-merchandise {
        grid-area: id-body-merchandise;
      }

      .id-body-pickup {
        grid-area: id-body-pickup;
      }

      .id-body-delivery {
        grid-area: id-body-delivery;
      }

      .id-body-footer {
        display: grid;
        gap: 1em 1em;
        grid-area: id-body-footer;
        grid-template-areas: 'id-body-footer-folder id-body-footer-documents id-body-footer-invoice id-body-footer-anomalies';
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
        grid-template-rows: 1fr;

        @include mixins.until(variables.$widescreen) {
          grid-template-areas:
            'id-body-footer-folder'
            'id-body-footer-documents'
            'id-body-footer-invoice'
            'id-body-footer-anomalies';
          grid-template-columns: 1fr;
          /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
          grid-template-rows: repeat(4, minmax(50px, auto));
        }

        &.is-documents-only {
          grid-template-areas: 'id-body-footer-folder id-body-footer-documents id-body-footer-documents';
          grid-template-columns: 1fr 1fr 1fr;

          @include mixins.until(variables.$widescreen) {
            grid-template-areas:
              'id-body-footer-folder'
              'id-body-footer-documents';
            grid-template-columns: 1fr;
            /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
            grid-template-rows: repeat(2, minmax(50px, auto));
          }

          .id-body-footer-invoice,
          .id-body-footer-anomalies {
            display: none;
          }
        }

        &.is-documents-anomalies-only {
          grid-template-areas: 'id-body-footer-folder id-body-footer-documents id-body-footer-documents id-body-footer-anomalies id-body-footer-anomalies';
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

          @include mixins.until(variables.$widescreen) {
            grid-template-areas:
              'id-body-footer-folder'
              'id-body-footer-documents'
              'id-body-footer-anomalies';
            grid-template-columns: 1fr;
            /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
            grid-template-rows: repeat(3, minmax(50px, auto));
          }

          .id-body-footer-invoice {
            display: none;
          }
        }

        /* stylelint-disable-next-line no-descending-specificity */
        > * {
          > div[class*='card'] {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
          }
        }

        .id-body-footer-folder {
          grid-area: id-body-footer-folder;
        }

        .id-body-footer-documents {
          grid-area: id-body-footer-documents;
          min-width: 0;

          > div[class*='card'] {
            >*:last-child {
              max-height: 147px;
              overflow: auto;

              @include mixins.until(variables.$widescreen) {
                max-height: inherit;
              }
            }
          }
        }

        /* stylelint-disable-next-line no-descending-specificity */
        .id-body-footer-invoice {
          grid-area: id-body-footer-invoice;
        }

        /* stylelint-disable-next-line no-descending-specificity */
        .id-body-footer-anomalies {
          grid-area: id-body-footer-anomalies;

          > div[class*='card'] {
            >*:last-child {
              max-height: 147px;
              overflow: auto;

              @include mixins.until(variables.$widescreen) {
                max-height: inherit;
              }
            }
          }
        }
      }
    }
  }
}
