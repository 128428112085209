@use '../../../../../abstracts/mixins';
@use '../../../../../abstracts/variables';
@use '../../../../../bases/helpers';

.order-card-list {
  hr {
    margin: 0.5rem 0;
  }

  .order-card-list-row {
    display: flex;
    padding: 0.5rem 0.25rem;
    position: relative;

    >div.order-card-list-row-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      span {
        margin: 0.25rem 0;

        &:first-child {
          margin: 0 0 0.25rem;
        }

        &:last-child {
          margin: 0.25rem 0 0;
        }
      }

      &:first-child {
        flex: 2;

        > span {
          &:first-child {
            color: variables.$primary;
            font-weight: bold;
          }

          &:nth-child(2) {
            font-weight: bold;
            margin: 0.75rem 0 -0.25rem;
          }
        }
      }

      &:last-child {
        flex: 1;
        text-align: right;

        /* stylelint-disable-next-line no-descending-specificity */
        > span {
          &:first-child {
            color: variables.$dark-grey;
          }

          &:nth-child(2) {
            &.is-yellow {
              color: #fa4;
            }

            &.is-green {
              color: #00ad56;
            }
          }

          &:last-child {
            /* stylelint-disable-next-line function-no-unknown */
            color: lighten($color: variables.$dark-grey, $amount: 35);
          }
        }
      }
    }
  }
}
