@use '../../../abstracts/variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/References';

.actuality {
  /* stylelint-disable-next-line function-no-unknown */
  background: lighten($color: variables.$dark-grey, $amount: 55);
  border-left: 2px solid variables.$dark-grey;
  margin: 0.5rem 0;
  padding: 0.5rem 0.75rem;

  i {
    color: variables.$dark-grey;
    font-weight: bold;
  }

  .date {
    color: variables.$dark-grey;
    display: block;
    margin-top: -5px;
    text-align: right;
  }

  &.is-info {
    /* stylelint-disable-next-line function-no-unknown */
    background: lighten($color: variables.$primary, $amount: 78);
    border-left: 2px solid variables.$primary;

    i {
      color: variables.$primary;
    }
  }

  &.is-warning {
    /* stylelint-disable-next-line function-no-unknown */
    background: lighten($color: $ms-color-sharedYellow10, $amount: 40);
    border-left: 2px solid $ms-color-sharedYellow10;

    i {
      color: $ms-color-sharedYellow10;
    }
  }

  &.is-error {
    /* stylelint-disable-next-line function-no-unknown */
    background: lighten($color: $ms-color-sharedRed20, $amount: 55);
    border-left: 2px solid $ms-color-sharedRed20;

    i {
      color: $ms-color-sharedRed20;
    }
  }
}
