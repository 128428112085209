@use '../../../abstracts/variables';
@use '../../../abstracts/mixins';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/References';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/mixins/Depth.Mixins';

.article {
  box-shadow: $ms-depth-shadow-16;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 245px;
  overflow: hidden;
  padding: 1rem;

  .card-title {
    -webkit-box-orient: vertical;
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      color: variables.$primary;
      font-weight: bold;
    }
  }

  .card-date {
    color: variables.$primary;
    display: block;
  }

  .card-tag-group {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    > span {
      /* stylelint-disable-next-line function-no-unknown */
      background-color: lighten($color: variables.$yellow, $amount: 5);

      > span {
        color: variables.$white;
        text-transform: capitalize;
      }
    }
  }

  .card-footer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 120px;
    justify-content: flex-start;

    @include mixins.until(variables.$tablet) {
      flex-direction: column;
      height: auto;
    }

    .card-footer-img-container {
      align-items: center;
      display: flex;
      flex: 2;
      text-align: center;

      img {
        color: transparent;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }

    p {
      flex: 3;

      a {
        color: variables.$yellow;
        font-weight: bold;
      }
    }
  }
}
