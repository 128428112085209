@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.transport-list {
  width: 100%;

  .transport-list-row {
    >* {
      // background-color: rgb(221, 230, 226);

      .transport-list-row-item {
        display: flex;
        flex-direction: column;
        height: 67px;
        justify-content: center;

        b {
          font-size: small;
          font-weight: bold;
        }

        img {
          max-height: 40px;
          width: auto;
        }

        .transport-list-row-item-logo {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          img {
            margin-left: 5px;
            max-height: 40px;
            width: auto;
          }

          span {
            align-self: center;
          }
        }

        .transport-list-row-item-date {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          img {
            margin-left: 5px;
            max-height: 30px;
            width: auto;
          }

          span {
            align-self: center;
          }
        }
      }
    }
  }
}
