@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~bulma/sass/utilities/initial-variables.sass';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~bulma/sass/utilities/derived-variables.sass';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~bulma/sass/utilities/mixins.sass';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import '~bulma-extensions/bulma-steps/dist/css/bulma-steps.sass';

@mixin step-item-content($step-color) {
  &.is-completed {
    &::before {
      background: $step-color !important;
    }

    .step-marker {
      background-color: $step-color !important;
    }

    .step-details {
      .step-title {
        color: $step-color !important;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
  }

  &.is-active {
    &::before {
      background: $step-color !important;
    }

    .step-marker {
      background-color: hsl(0deg, 0%, 100%);
      border-color: $step-color !important;
      color: $step-color !important;
    }

    .step-details {
      .step-title {
        color: $step-color !important;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
  }
}

.steps {
  display: flex;
  justify-content: center;

  .step-item {
    @include step-item-content(variables.$green);

    max-width: 200px;

    &:not(.is-completed):not(.is-warning):not(.is-active):not(.is-canceled) {
      &::before {
        background: linear-gradient(90deg, rgba(variables.$primary, 0.25) 0%, rgba(variables.$primary, 0.25) 100%) !important;
      }
    }

    &.is-error {
      @include step-item-content(#f14668);
    }

    &.is-warning {
      @include step-item-content(#f8c115);
    }

    &.is-canceled {
      @include step-item-content(rgba(10, 10, 10, 91.1%));
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .step-marker {
    height: 1.25rem !important;
    top: 0.5rem;
    width: 1.25rem !important;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .step-details {
    /* stylelint-disable-next-line no-descending-specificity */
    .step-title {
      color: variables.$dark-primary;
      font-size: 18px !important;
      word-break: keep-all;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
  }
}
