.index {
  // Example

  .action-filter-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .separator {
      min-width: 5px;
    }
  }
}
