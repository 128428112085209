/* stylelint-disable max-line-length */
/* stylelint-disable no-descending-specificity */
@use '../../abstracts/mixins';
@use '../../abstracts/variables';
@use '../../bases/helpers';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/variables/Depth.Variables';
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'node_modules/@fluentui/react/dist/sass/mixins/Depth.Mixins';

.tracking-body-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 1em 1em;

  .tracking-body-content-top {
    display: grid;
    flex: 2 1 auto;
    gap: 1em 1em;
    grid-auto-columns: 1fr;
    grid-template-areas:
      'tracking-body-content-top-contractor tracking-body-content-top-status tracking-body-content-top-status tracking-body-content-top-merchandise tracking-body-content-top-merchandise'
      'tracking-body-content-top-transport tracking-body-content-top-status tracking-body-content-top-status tracking-body-content-top-merchandise tracking-body-content-top-merchandise';
    grid-template-columns: repeat(5, 1fr);
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: repeat(2, 1fr);

    @include mixins.until(variables.$widescreen) {
      grid-template-areas:
        'tracking-body-content-top-contractor tracking-body-content-top-transport'
        'tracking-body-content-top-status tracking-body-content-top-status'
        'tracking-body-content-top-merchandise tracking-body-content-top-merchandise';
      grid-template-columns: 1fr;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-rows: repeat(3, minmax(50px, auto));
    }

    @include mixins.until(variables.$tablet) {
      grid-template-areas:
        'tracking-body-content-top-contractor'
        'tracking-body-content-top-transport'
        'tracking-body-content-top-status'
        'tracking-body-content-top-merchandise';
      grid-template-columns: 1fr;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-rows: repeat(4, minmax(50px, auto));
    }

    > * {
      > div[class*='card'] {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
      }
    }

    .tracking-body-content-top-contractor {
      grid-area: tracking-body-content-top-contractor;
    }

    .tracking-body-content-top-transport {
      grid-area: tracking-body-content-top-transport;
    }

    .tracking-body-content-top-status {
      grid-area: tracking-body-content-top-status;

      > div[class*='card'] {
        >*:last-child {
          max-height: 368px;
          overflow: auto;

          @include mixins.until(variables.$widescreen) {
            max-height: inherit;
          }
        }
      }
    }

    .tracking-body-content-top-merchandise {
      grid-area: tracking-body-content-top-merchandise;

      > div[class*='card'] {
        >*:last-child {
          max-height: 368px;
          overflow: auto;

          @include mixins.until(variables.$widescreen) {
            max-height: inherit;
          }
        }
      }
    }
  }

  .tracking-body-content-bottom {
    display: grid;
    flex: 1 1 auto;
    gap: 1em 1em;
    grid-auto-columns: 1fr;
    grid-template-areas: 'tracking-body-content-bottom-pickup tracking-body-content-bottom-delivery tracking-body-content-bottom-folder tracking-body-content-bottom-documents';
    grid-template-columns: repeat(2, 1fr);
    /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
    grid-template-rows: repeat(1, 1fr);

    @include mixins.until(variables.$widescreen) {
      grid-template-areas:
        'tracking-body-content-bottom-pickup'
        'tracking-body-content-bottom-delivery'
        'tracking-body-content-bottom-folder'
        'tracking-body-content-bottom-documents';
      grid-template-columns: 1fr;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-rows: repeat(3, minmax(50px, auto));
    }

    > * {
      > div[class*='card'] {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
      }
    }

    .tracking-body-content-bottom-pickup {
      grid-area: tracking-body-content-bottom-pickup;
    }

    .tracking-body-content-bottom-delivery {
      grid-area: tracking-body-content-bottom-delivery;
    }

    .tracking-body-content-bottom-folder {
      grid-area: tracking-body-content-bottom-folder;
    }

    .tracking-body-content-bottom-documents {
      grid-area: tracking-body-content-bottom-documents;
    }
  }
}
