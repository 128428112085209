@use '../../../abstracts/mixins';
@use '../../../abstracts/variables';
@use '../../../bases/helpers';

.company-list {
  width: 100%;

  .company-list-row {
    display: flex;
    flex-direction: column;
    height: 67px;
    justify-content: center;

    img {
      max-height: 40px;
      width: auto;
    }
  }

  .company-list-row-button {
    display: flex;
    flex-direction: column;
    height: 67px;
    justify-content: center;
  }
}
