@use '../../../../abstracts/mixins';
@use '../../../../abstracts/variables';
@use '../../../../bases/helpers';

.operating-center-modal {
  padding-top: 1rem;

  div[class*='column'] {
    padding-top: 0;
  }
}
